<template>
  <!-- <div> -->
  <div class="col-lg-4 col-md-6 col-12 mt-4">
    <div class="card">
      <div class="card-header text-left">
        <h6 class="skeleton-header skleton-rectangle"></h6>
      </div>
      <div class="card-body ml-2">
        <div class="row mt-1">
          <i class="far fa-notes-medical mr-2"></i>
          <h6 class="skeleton-medical skleton-rectangle"></h6>
        </div>
        <div class="row mt-1">
          <i class="far fa-disease mr-2"></i>
          <h6 class="skeleton-medical skleton-rectangle"></h6>
        </div>
        <div class="row mt-1">
          <i class="far fa-stopwatch mr-2"></i>
          <h6 class="skeleton-medical skleton-rectangle"></h6>
        </div>
      </div>
      <!-- edit button in the corner -->
      <!-- {{info.jejakTerakhirStage}} -->
      <div style="position: absolute; right: 10px; bottom: 10px">
        <h6 class="skleton-circle"></h6>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
export default {};
</script>

<style scoped>
.skeleton-header {
  width: 40%;
  height: 35px;
}

.skeleton-medical {
  width: 100px;
  height: 20px;
}

.skleton-rectangle {
  -webkit-mask: linear-gradient(#000 0 0) top / 100% 100%;
  -webkit-mask-repeat: no-repeat;
  background: linear-gradient(90deg, #ccca 40%, #fffa, #ccca 60%) right/300%
    100%;
  animation: m 1s linear infinite;
}

.skleton-circle {
  width: 40px;
  height: 40px;
  -webkit-mask: radial-gradient(circle closest-side, #000 90%, #0000) bottom
    left/100% 100%;
  background: linear-gradient(90deg, #ccca 40%, #fffa, #ccca 60%) right/300%
    100%;
  animation: m 1s linear infinite;
}

@keyframes m {
  to {
    background-position: left;
  }
}
</style>
