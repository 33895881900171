<template>
  <!-- <div> -->
  <div class="col-lg-4 col-md-6 col-12 mt-4">
    <div class="card">
      <div class="card-header text-left">
        {{ info.nama }}
        <i
          :class="
            `float-right fas fa-2x fa-check-circle ${iconColor(info.status)}`
          "
          v-if="info.status !== null"
        ></i>
        <!-- <div class="pull-right ">
          </div> -->
      </div>
      <div class="card-body ml-2">
        <div class="row mt-1">
          <i class="far fa-notes-medical mr-2"></i>
          <h6>{{ info.diagnosa }}</h6>
        </div>
        <div class="row mt-1">
          <i class="far fa-disease mr-2"></i>
          <h6>
            <a href="" data-toggle="modal" :data-target="'#Modal' + info.id">{{
              getEnum(info.jejakTerakhirStage)
            }}</a>
          </h6>
        </div>
        <div class="row mt-1">
          <i class="far fa-stopwatch mr-2"></i>
          <h6>{{ info.jejakTerakhirTimestamp | timeChange}}</h6>
        </div>
      </div>
      <!-- edit button in the corner -->
      <!-- {{info.jejakTerakhirStage}} -->
      <div
        v-if="info.jejakTerakhirStage == 'PASIEN_BELUM_MASUK'"
        style="position: absolute;
        right:    10px;
        bottom:   10px;"
      >
        <button
        v-if="!loading"
          type="button"
          class="btn btn-primary cardBtn"
          @click.prevent="isModalEdit = true"
          style="border-radius: 50px; background-color: #2f5884;
        border-color: none;"
        >
          <i class="fas fa-pen"></i>
        </button>

        <ModalAdd
          v-if="isModalEdit"
          @closeModal="enabelClose()"
          :id="info.id"
          :edit="true"
          :info="info"
        />
      </div>
    </div>

    <ModalReff :id="info.id" :info="info" />
  </div>
  <!-- </div> -->
</template>
<script>
import { mapState } from "vuex";
// import ModalAdd from '../components/ModalAdd'
import ModalReff from "../components/ModalReff";
// import ModalAdd from '../components/ModalAdd'
import ModalEdit from "../components/ModalEdit";
import SpinLoading from "../components/SpinLoading";
export default {
  props: {
    info: Object,
  },
  filters: {
    timeChange(val) {
      var a = new Date(val)
      return a.toLocaleString( 'hi-IN' );
    }
  },
  data() {
    return {
      isModalEdit: false,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      isModalAdd: (state) => state.isModalAdd,
    }),
  },
  methods: {
    getEnum(val) {
      switch (val) {
        case "PASIEN_BELUM_MASUK":
          return "Pasien Belum Masuk";
        case "PASIEN_DATANG":
          return "Pasien Datang";
        case "DIRUJUK_KETEMPAT_LAIN":
          return "Dirujuk Ketempat Lain";
        case "DALAM_PERAWATAN":
          return "Dalam Perawatan";
        case "PASIEN_PULANG":
          return "Pasien Pulang";
        case "TIDAK_JADI_DATANG":
          return "Tidak Jadi Datang";
        case "DALAM_PROSES_PEMBAYARAN":
          return "Pasien Belum Masuk";
        case "SUDAH_TERBAYARKAN":
          return "Pasien Belum Masuk";
        case "CLOSED":
          return "Closed";
      }
    },
    enabelClose() {
      $("#exampleModal" + this.info.id).modal("hide");
      // console.log('ini di hide', "#exampleModal"+this.info.id);
      this.isModalEdit = false;
    },
    enableModalAdd() {
      this.$store.commit("SET_IS_MODAL_ADD", true);
    },
    iconColor(status) {
      switch (status) {
        case 1:
          return "text-danger";
          break;
        case 2:
          return "text-warning";
          break;
        case 3:
          return "text-success";
          break;
      }
    },
  },
  components: {
    ModalReff,
    ModalEdit,
    ModalAdd: () => ({
      component: new Promise(function(resolve, reject) {
        setTimeout(function() {
          resolve(
            import(
              /* webpackChunkName: "modal-reservasi-dokter" */ "./../components/ModalAdd"
            )
          );
        }, 200);
      }),
      // loading: SpinLoading,
    }),
  },
  mounted() {
    // alert($("#exampleModal"+this.info.id).hasClass('in'));
  },
};
</script>

<style></style>
