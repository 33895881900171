import { render, staticRenderFns } from "./CardsReff.vue?vue&type=template&id=bba0c642&"
import script from "./CardsReff.vue?vue&type=script&lang=js&"
export * from "./CardsReff.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports