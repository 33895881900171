import { render, staticRenderFns } from "./Skleton.vue?vue&type=template&id=296b7b6d&scoped=true&"
import script from "./Skleton.vue?vue&type=script&lang=js&"
export * from "./Skleton.vue?vue&type=script&lang=js&"
import style0 from "./Skleton.vue?vue&type=style&index=0&id=296b7b6d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296b7b6d",
  null
  
)

export default component.exports